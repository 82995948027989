<template>
  <div :id="viewCSSId" :class="viewCSSClasses" ref="scene">
    <div class="section-foreground container">
      <div class="layout-column rows layout-align-start-center">
        <div class="row-1 row layout-column layout-align-center-center">
          <div class="layout-row cols layout-align-center-center">
            <div class="col-1-1 col-padding layout-column">
              <lead
                id="lead-left"
                :show="showLead"
                class="parallax-item parallax-relative"
                data-depth="1.8"
                :textAlign="'right'"
                :timescale="1"
                >{{ data.meta.description }}</lead
              >
            </div>
            <div class="col-1-2 col-padding layout-column">
              <section-title
                :partNo="part.id"
                :partTitle="part.title"
                data-depth="2.7"
                class="parallax-item parallax-relative"
                :show="showTitle"
              ></section-title>
            </div>
          </div>
        </div>
        <div
          class="row-2 row col-padding layout-column layout-align-center-start"
        ></div>
        <div
          class="
            row-4 row
            layout-column
            relative
            layout-align-start-end
            col-padding
          "
        >
          <ink
            class="absolute"
            :customTimeline="timelines.timelineInk"
            :show="showInk"
            :image="data.images.ink"
          ></ink>
          <box-slider
            id="slider-retreats"
            :show="showDiagram"
            :data="data.sliders"
          ></box-slider>
        </div>
      </div>
    </div>

    <div class="container absolute fluid">
      <decal
        id="decal"
        class="absolute bottom-left"
        :show="showDecal"
        :image="data.images.decal"
        :customTimeline="timelines.timelineDecal"
        :parallaxDepth="3"
      ></decal>
    </div>
    <background-image-full
      :image="data.images.background"
      :show="showBackground"
      :imageCss="{ objectPosition: 'center center' }"
      :mask="true"
      :maskOpacity="0"
      :timescale="1.8"
      :timescaleReverse="3"
    ></background-image-full>

    <div id="container-pattern" class="fixed fluid container z-index-0">
      <pattern-overlay
        :show="showPattern"
        :image="data.images.pattern"
        :align="'right'"
        id="pattern1"
      ></pattern-overlay>
      <pattern-overlay
        :show="showPattern"
        :image="data.images.pattern2"
        id="pattern2"
      ></pattern-overlay>
    </div>

    <modal-main
      v-for="modal in data.modals"
      :data="modal"
      :key="modal.name"
    ></modal-main>
  </div>
</template>

<script>
import { chapterPart } from "@/mixins/core.js";

import SectionTitle from "@/components/layout/SectionTitle.vue";
import BackgroundImageFull from "@/components/ui/BackgroundImageFull.vue";
import Decal from "@/components/ui/Decal.vue";
import Ink from "@/components/ui/Ink.vue";
import Lead from "@/components/ui/Lead.vue";
import BoxSlider from "@/components/ui/BoxSlider.vue";
import PatternOverlay from "@/components/ui/PatternOverlay.vue";

import ModalMain from "@/modals/ModalMain.vue";

export default {
  name: "Chapter-4-2",
  mixins: [chapterPart],
  components: {
    SectionTitle,
    BackgroundImageFull,
    Decal,
    Ink,
    Lead,
    BoxSlider,
    PatternOverlay,
    ModalMain,
  },
  data() {
    return {
      doParallax: true,
      itemsToShow: [
        ["showDelay", 0],
        ["showBackground", 600],
        ["showTitle", 300],
        ["showDecal", 100],
        ["showPattern", 0],
        ["showDiagram", 200],
        ["showQuote", 0],
        ["showLead", 100],
        ["showInk", 1000],
      ],
      timelines: {
        timelineInk: {
          duration: 0.7,
          animations: {
            scale: 1,
            x: "+=20%",
            ease: Power2.easeOut,
          },
        },
        timelineLeadHeading: {
          start: 0,
          duration: 0.7,
          animations: {
            autoAlpha: 0,
            ease: Power1.easeInOut,
          },
        },
        timelineDecal: {
          duration: 1.2,
          animations: {
            ease: Power1.easeInOut,
            autoAlpha: 0,
            scale: "0.8",
            y: "+=20%",
            x: "-=20%",
          },
        },
      },
    };
  },
  computed: {},
};
</script>

<style scoped>
.view-part {
}
.rows {
}
.row {
  width: 100%;
  max-width: 1340px;
}
.row-1 {
}
.row-4 {
  margin-top: -0.4rem;
}
.col-1 {
  max-width: 100%;
}
.col-2 {
  max-width: 50%;
}

.col-1-1 {
  margin: 0.6rem 0 0 0;
}
.col-1-2 {
  padding-left: 0;
}
.section-title {
  max-width: 4.6em;
}
.section-decal {
  max-width: 40vw;
  margin: 0;
  bottom: -1%;
  left: -0.8%;
  min-width: 250px;
}
#pattern1 {
  right: -18%;
}
#pattern2 {
  left: -22%;
}

.section-ink {
  right: -11.8% !important;
  left: auto !important;
  top: -4% !important;
  opacity: 0.8;
}
/deep/ .section-ink img {
  max-width: 1200px;
}

/deep/ #lead-left .lead {
  max-width: 43em;
}
/deep/ #lead-right .lead-heading {
  margin: 0.483em 0px 0px;
}
/deep/ #lead-right .lead-wrap {
  display: none !important;
}
/deep/ .slide-logo img {
  padding: 0 0 1.1em 1.1em;
}
/deep/ #track-your-life .slide-logo img {
  padding-left: 0;
}
/deep/ #healing-house .slide-text {
  max-width: 19em;
}

/deep/ #return-to-the-heart .slide-title {
  max-width: 5em;
}

/*=============================================
=                  MEDIA QUERIES             =
=============================================*/

@media screen and (max-width: 1600px) {
  .section-ink {
    top: -9% !important;
  }
}

@media screen and (max-width: 1440px) {
  .section-ink {
    top: -9% !important;
    right: -18% !important;
  }
  /deep/ .section-ink img {
    max-width: 92%;
  }
}

@media screen and (max-width: 1366px) {
  /deep/ .section-ink img {
    max-width: 90%;
  }
  .section-ink {
    right: -22% !important;
    top: -7% !important;
  }
}

@media screen and (max-width: 1200px) {
  /deep/ .section-ink img {
    max-width: 85%;
  }
  .section-ink {
    right: -30% !important;
    top: -4% !important;
  }
}

@media screen and (max-width: 1024px) {
  /deep/ .section-ink img {
    max-width: 85%;
  }
  .section-ink {
    right: -32% !important;
    top: -3% !important;
  }
  .row-4 {
    padding-bottom: 4rem;
  }
}

@media screen and (max-width: 1023px),
  screen and (min-width: 992px) and (max-width: 1024px) and (orientation: portrait) {
  #lead-left {
    text-align: left;
  }
  /deep/ #lead-left .lead {
    padding: 0;
    text-align: left;
    max-width: 52em;
  }
  /deep/ #lead-left .lead-line {
    left: -2px;
    right: auto;
  }
  .row-1 {
    margin: 0 0 0.9rem;
  }
  .row-1 .cols {
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
  }
  .row-1 .cols > * {
    max-width: 100%;
    width: 100%;
    align-items: center;
  }
  .col-1-1 {
    order: 2;
    margin: 0;
  }
  .col-1-2 {
    order: 1;
  }
}

@media screen and (max-width: 991px) {
  .row-4 {
    padding-bottom: 12rem;
    align-items: center;
  }
  .landscape .row-4 {
    padding-bottom: 8rem;
  }
  .section-decal {
    max-width: 45vw;
  }
  .portrait .section-decal {
    max-width: 55vw;
  }
}

@media screen and (max-width: 810px) {
  /deep/ #lead-left .lead {
    max-width: 42em;
  }
  .row-4 {
    padding-left: 0;
    padding-right: 0;
    align-items: center;
  }
}

@media screen and (max-width: 767px) {
  /deep/ .section-ink img {
    max-width: 100%;
  }
  .section-ink {
    right: -10% !important;
    top: -4% !important;
    width: 100vw;
  }
}

@media screen and (max-width: 680px) {
  #lead-left {
    margin-right: var(--col-padding-adjust);
  }
  /deep/ .section-ink img {
    max-width: 110%;
  }
  .section-ink {
    right: -10% !important;
    top: -2% !important;
  }
}

@media screen and (max-width: 639px) {
  /deep/ .section-ink img {
    max-width: 120%;
  }
  .section-ink {
    right: -2% !important;
    top: -4% !important;
  }
}

@media screen and (max-width: 520px) {
  /deep/ .section-ink img {
    max-width: 135%;
  }
  .section-ink {
    right: 3% !important;
    top: -1% !important;
  }
}

@media screen and (max-width: 479px) {
}

@media screen and (max-width: 420px) {
  /deep/ .section-ink img {
    max-width: 150%;
  }
  .section-ink {
    right: 12% !important;
    top: 0 !important;
  }
}

@media screen and (max-width: 390px) {
  /deep/ .section-ink img {
    max-width: 160%;
  }
  .section-ink {
    right: 20% !important;
    top: 3% !important;
  }
}
</style>
